/* @flow */

import type { History } from "react-router";

import React, { useContext, useState, useEffect } from "react";
import { Route, Switch } from "react-router";
import Redirect from "@crossroads/react-router-redirect";
import {
  HomeData,
  RedeemData,
  BrandData,
  RouteData,
  CustomerData,
  CmsData,
  OrderData,
  SearchData,
  NewsPostsData,
  AffiliateCategoriesData,
  AffiliateListData,
  AffiliateListSelectedData,
  AffiliateData,
  TransactionsData,
  LeaderboardData,
  ConnectedAccountsData,
  QuestionnaireData,
  QuestionnaireListData,
  QuizListData,
  QuizHistoryListData,
  QuizData,
  QuizHistoryData,
  QuizHistoryRowsData,
  AllProductsData,
  OffersData,
  CurrentInfoListData,
  PhotoServiceData,
  PhotoServiceListData,
  AllFormsData,
  FormRowsData,
  FormData,
  FormSubmitsData,
  ViewModeData,
  RegisterFormConfigData,
  AccountFormConfigData,
  DistrictListData,
  MemberGroupIsOwnerData,
} from "data";
import { useData } from "crustate/react";
import { StoreInfoContext } from "entrypoint/shared";
import App from "components/App";
import GuestApp from "components/GuestApp";
import CategoryView, { HintCategoryView } from "components/CategoryView";
import ProductView, { HintProductView } from "components/ProductView";
import BrandView from "components/BrandView";
import BrandListView from "components/BrandListView";
import CategoryListView from "components/CategoryListView";
import RedeemView from "components/RedeemView";
import CmsPageView from "components/CmsPageView";
import SearchView from "components/SearchView";
import HomeView from "components/HomeView";
import AllProductsView from "components/AllProductsView";
import CheckoutView from "components/CheckoutView";
import LoginView from "components/LoginView";
import AccountView from "components/AccountView";
import TransactionsView from "components/AccountView/TransactionsView";
import AccountingRecordsView from "components/AccountView/AccountingRecordsView";
import AdditionalMemberNumbersView from "components/AccountView/AdditionalMemberNumbersView";
import LeaderboardView from "components/LeaderboardView";
import ReportsView from "components/ReportsView";
import ReportsHistoryView from "components/ReportsView/ReportsHistoryView";
import PointsView from "components/AccountView/PointsView";
import NewsListView from "components/NewsListView";
import OfferListView from "components/OfferListView";
import NewsView from "components/NewsView";
import AffiliateListView from "components/AffiliateListView";
import AffiliateView from "components/AffiliateView";
import AffiliateSubpage from "components/AffiliateSubpage";
import NotFoundView from "components/NotFoundView";
import ResetPasswordView from "components/ResetPasswordView";
import RegisterView from "components/RegisterView";
import SuccessView from "components/SuccessView";
import TermsView from "components/TermsView";
import { usePointsToPrice } from "helpers/utils";
import QuestionnaireListView from "components/QuestionnaireListView";
import QuestionnaireView from "components/QuestionnaireView";
import QuizListView from "components/QuizListView";
import QuizView from "components/QuizView";
import QuizHistoryView from "components/QuizHistoryView";
import UploadedImages from "components/UploadedImages";
import AboutView from "../AboutView";
import FormView from "components/FormView";
import MemberGroupView from "components/MemberGroupView";
import FormListView from "components/FormListView";

import { locationWithDefaults, getDefaultSort } from "helpers/use-update-product-list";
import LoadingView from "components/LoadingView";
import FaqView from "components/FaqView";
import FullScreenLoadingView from "components/LoadingView/full-screen";
import ReloadConfigLoadingView from "components/ReloadConfigLoadingView";
import { parseParams } from "@crossroads/location-search-string";
import additionalRoutes from "components/Routes/additional-routes";
import usePrevious from "helpers/use-previous";
import { AnalyticsContext } from "@crossroads/analytics";

import styles from "./styles.scss";

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: {
    push: (path: string) => void,
    replace: ({}) => void,
  },
};

const LoginRouteView = ({ location }: { location: Location }) => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint && data.hint.type === "cms_page") {
      return <CmsPageView cmsPage={data.hint.cmsPage} />;
    }

    return <LoadingView />;
  }

  if (!data.route) {
    return <Redirect to={{ pathname: "/login", search: ((location.pathname === "/login" ? "" : location.pathname) + location.search) }} />;
  }

  if (data.route.type === "cms_page") {
    return <CmsPageView cmsPage={data.route.cmsPage} isLoggedIn={false} />;
  }

  return <Redirect to={{ pathname: "/login", search: ((location.pathname === "/login" ? "" : location.pathname) + location.search) }} />;
};

const RouteView = ({ location, history }: RouteViewProps) => {
  const data = useData(RouteData);

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProductView product={data.hint.product} />;
        case "category":
          return <HintCategoryView category={data.hint.category} />;
        case "cms_page":
          return <CmsPageView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <LoadingView />;
  }

  if (!data.route) {
    // TODO: Check error msg
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      return <CategoryView category={data.route.category} location={location} history={history} />;
    case "cms_page":
      return <CmsPageView cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} />;
    default:
      return null;
  }
};

const Routes = ({ location, history }: RoutesProps) => {
  const customer = useData(CustomerData);
  const loggedIn = Boolean(typeof customer.data !== "undefined" && customer.data);

  const {
    info,
    routes,
    configuration,
    content: {
      transactionsview,
      earnview,
    },
  } = useContext(StoreInfoContext);

  const termsViewUrl = (routes.termsView !== undefined && typeof routes.termsView.url === "string" &&
    routes.termsView.url.length > 0) ? routes.termsView.url : "";
  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";
  const pointsToPrice = usePointsToPrice();
  const defaultSort = getDefaultSort(configuration);
  const prevCustomerState = usePrevious(customer.state);
  const [loggedOut, setLoggedOut] = useState(false);
  const redirectLink = location && location.search ? location.search.slice(1) : "";
  const gaContext = useContext(AnalyticsContext);
  const flag = transactionsview.allTransactions === true ? "ALL_INCLUDING_CANCELED_AND_REFUND" : null;
  const onlyNumbers = /^\d*$/;

  useEffect(() => {
    if (customer.state === "NOT_LOGGED_IN" && prevCustomerState === "LOGGING_OUT") {
      setLoggedOut(true);
    }

    if (customer.state === "LOGGED_IN" && prevCustomerState === "LOGGING_IN") {
      gaContext.loggedIn();
    }
  }, [customer, prevCustomerState]);

  // Prevent dev server infinite loop rendering
  if (customer.state === "INITING") {
    return null;
  }

  if (customer.state === "REDIRECTING") {
    return <FullScreenLoadingView />;
  }

  // Reload app at login and logout to set distric-specific configuration
  if (Boolean(configuration.reloadConfigToggle) && typeof window !== "undefined" &&
    ((loggedIn && location.pathname === "/login") || loggedOut)) {
    window.location.reload();
    return <ReloadConfigLoadingView title={configuration.reloadConfigTitle} />;
  }

  if (!loggedIn) {
    return (
      <ViewModeData.Provider>
        <GuestApp location={location} history={history}>
          <Switch>
            {routes.passwordResetView &&
            routes.passwordResetView.toggle !== undefined &&
            routes.passwordResetView.toggle &&
            typeof routes.passwordResetView.url === "string" &&
            routes.passwordResetView.url.length > 0 &&
              <Route exact path={routes.passwordResetView.url} component={ResetPasswordView} />
            }
            <Route exact path="/login" component={LoginView} />
            {routes.registerView &&
            routes.registerView.toggle !== undefined &&
            routes.registerView.toggle &&
            typeof routes.registerView.url === "string" &&
            routes.registerView.url.length > 0 &&
              <Route
                exact path={routes.registerView.url + "/:partnerId?"}
                render={({ match, location }) => {
                  const routeParams = match.params;
                  const queryParams = parseParams(location.search);
                  const partnerId = () => {
                    if (onlyNumbers.test(routeParams.partnerId)) {
                      return Number.parseInt(routeParams.partnerId, 10);
                    }

                    return Number.parseInt(info.partnerId, 10);
                  };

                  return (
                    <RegisterFormConfigData.Provider partner={partnerId()}>
                      <DistrictListData.Provider partner={partnerId()}>
                        <RegisterView queryParams={queryParams} partnerId={partnerId()} />
                      </DistrictListData.Provider>
                    </RegisterFormConfigData.Provider>
                  );
                }}
              />
            }
            {termsViewUrl &&
              <Route
                exact path={termsViewUrl + "/:partnerId?"}
                render={({ match }) => {
                  const { partnerId } = match.params;
                  const id = (partnerId !== undefined && onlyNumbers.test(partnerId)) ?
                    Number.parseInt(partnerId, 10) : null;
                  return (
                    <TermsView isLoggedIn={loggedIn} partnerId={id} />
                  );
                }}
              />
            }
            <Route
              path="/(.+)"
              render={({ location: { pathname, state, search } }) => {
                const { page } = parseParams(search);

                return (
                  <RouteData.Provider
                    incVat={false}
                    url={pathname}
                    location={locationWithDefaults(location, pointsToPrice, defaultSort)}
                    name={`route_${pathname}`}
                    hint={state && state.hint}
                    page={page}
                  >
                    <LoginRouteView location={location} />
                  </RouteData.Provider>
                );
              }}
            />
            <Redirect to={{ pathname: "/login", search: ((location.pathname === "/login" ? "" : location.pathname) + location.search) }} />
          </Switch>
        </GuestApp>
      </ViewModeData.Provider>
    );
  }

  return (
    <ViewModeData.Provider>
      <App location={location} history={history}>
        <Switch>
          <Redirect from="/login" to={redirectLink} />
          <Route
            exact
            path={["/", "/member/Ahlsell/index.jsp", "/index.html"]}
            render={() => {
              return (
                <HomeData.Provider>
                  <AffiliateListSelectedData.Provider size={8}>
                    <NewsPostsData.Provider page={1} size={20}>
                      <PhotoServiceData.Provider>
                        <PhotoServiceListData.Provider>
                          <CurrentInfoListData.Provider page="CURRENTINFO1">
                            <HomeView />
                          </CurrentInfoListData.Provider>
                        </PhotoServiceListData.Provider>
                      </PhotoServiceData.Provider>
                    </NewsPostsData.Provider>
                  </AffiliateListSelectedData.Provider>
                </HomeData.Provider>
              );
            }}
          />

          {routes.allProductsView &&
          routes.allProductsView.toggle !== undefined &&
          routes.allProductsView.toggle &&
          typeof routes.allProductsView.url === "string" &&
          routes.allProductsView.url.length > 0 &&
            <Route
              exact
              path={routes.allProductsView.url}
              render={({ match, location, search }) => {
                const { page } = parseParams(search);

                return (
                  <AllProductsData.Provider
                    incVat={false}
                    location={locationWithDefaults(location, pointsToPrice, defaultSort)}
                    query={decodeURIComponent(match.params.query)}
                    name="all-products"
                    page={page}
                  >
                    <AllProductsView className={styles.popularProducts} />
                  </AllProductsData.Provider>
                );
              }}
            />
          }

          {routes.termsView &&
          routes.termsView.toggle !== undefined &&
          routes.termsView.toggle &&
          typeof routes.termsView.url === "string" &&
          routes.termsView.url.length > 0 &&
            <Route exact path={routes.termsView.url}>
              <TermsView />
            </Route>
          }

          {routes.newsListView &&
          routes.newsListView.toggle !== undefined &&
          routes.newsListView.toggle &&
          typeof routes.newsListView.url === "string" &&
          routes.newsListView.url.length > 0 &&
            <Route
              exact
              path={routes.newsListView.url}
              render={() => (
                <NewsPostsData.Provider page={1} size={20}>
                  <NewsListView />
                </NewsPostsData.Provider>
              )}
            />
          }

          {routes.offerListView &&
          routes.offerListView.toggle !== undefined &&
          routes.offerListView.toggle &&
          typeof routes.offerListView.url === "string" &&
          routes.offerListView.url.length > 0 &&
            <Route
              exact
              path={routes.offerListView.url}
              render={() => (
                <OffersData.Provider page={1} size={20}>
                  <OfferListView />
                </OffersData.Provider>
              )}
            />
          }

          {routes.newsListView &&
          routes.newsListView.toggle !== undefined &&
          routes.newsListView.toggle &&
          typeof routes.newsListView.url === "string" &&
          routes.newsListView.url.length > 0 &&
            <Route
              exact
              path={routes.newsListView.url + "/:title"}
              render={({ match }) => (
                <NewsPostsData.Provider page={1} size={20}>
                  <NewsView title={match.params.title} />
                </NewsPostsData.Provider>
              )}
            />
          }

          {routes.redeemView &&
          routes.redeemView.toggle !== undefined &&
          routes.redeemView.toggle &&
          typeof routes.redeemView.url === "string" &&
          routes.redeemView.url.length > 0 &&
            <Route
              exact
              path={routes.redeemView.url}
              render={() => (
                <RedeemData.Provider>
                  <RedeemView />
                </RedeemData.Provider>
              )}
            />
          }

          <Route
            exact
            path="/forms/:id"
            render={({ match }) => {
              const formID = parseInt(match.params.id, 10);
              return (
                <AllFormsData.Provider>
                  <FormRowsData.Provider id={1}>
                    <FormView formId={formID} history={history} />
                  </FormRowsData.Provider>
                </AllFormsData.Provider>
              );
            }}
          />

          {routes.formListView &&
          routes.formListView.toggle !== undefined &&
          routes.formListView.toggle &&
          typeof routes.formListView.url === "string" &&
          routes.formListView.url.length > 0 &&
            <Route
              exact
              path={routes.formListView.url}
              render={() => {
                return (
                  <FormData.Provider id={1}>
                    <FormRowsData.Provider id={1}>
                      <FormSubmitsData.Provider id={1}>
                        <FormListView routes={routes.formListView} />
                      </FormSubmitsData.Provider>
                    </FormRowsData.Provider>
                  </FormData.Provider>
                );
              }}
            />
          }

          {routes.earnViewSubpage &&
          routes.earnViewSubpage.toggle !== undefined &&
          routes.earnViewSubpage.toggle &&
          typeof routes.earnViewSubpage.url === "string" &&
          routes.earnViewSubpage.url.length > 0 &&
            <Route
              exact
              path={`${routes.earnViewSubpage.url}/:query`}
              render={() => (
                <AffiliateCategoriesData.Provider>
                  <AffiliateListData.Provider name="earn" page={1} size={9999}>
                    <AffiliateListSelectedData.Provider size={12}>
                      <AffiliateSubpage
                        title={earnview.subPageTitle ?? ""}
                        description={earnview.subPageDescription ?? ""}
                      />
                    </AffiliateListSelectedData.Provider>
                  </AffiliateListData.Provider>
                </AffiliateCategoriesData.Provider>
              )}
            />
          }

          {routes.earnViewSubpage2 &&
          routes.earnViewSubpage2.toggle !== undefined &&
          routes.earnViewSubpage2.toggle &&
          typeof routes.earnViewSubpage2.url === "string" &&
          routes.earnViewSubpage2.url.length > 0 &&
            <Route
              exact
              path={`${routes.earnViewSubpage2.url}/:query`}
              render={() => (
                <AffiliateCategoriesData.Provider>
                  <AffiliateListData.Provider name="earn" page={1} size={9999}>
                    <AffiliateListSelectedData.Provider size={12}>
                      <AffiliateSubpage
                        title={earnview.subPage2Title ?? ""}
                        description={earnview.subPage2Description ?? ""}
                      />
                    </AffiliateListSelectedData.Provider>
                  </AffiliateListData.Provider>
                </AffiliateCategoriesData.Provider>
              )}
            />
          }

          {routes.earnView &&
          routes.earnView.toggle !== undefined &&
          routes.earnView.toggle &&
          typeof routes.earnView.url === "string" &&
          routes.earnView.url.length > 0 &&
            <Route
              exact
              path={routes.earnView.url}
              render={() => (
                <AffiliateCategoriesData.Provider>
                  <AffiliateListData.Provider name="earn" page={1} size={9999}>
                    <AffiliateListSelectedData.Provider size={12}>
                      <AffiliateListView />
                    </AffiliateListSelectedData.Provider>
                  </AffiliateListData.Provider>
                </AffiliateCategoriesData.Provider>
              )} />
          }

          {routes.earnView &&
          routes.earnView.toggle !== undefined &&
          routes.earnView.toggle &&
          typeof routes.earnView.url === "string" &&
          routes.earnView.url.length > 0 &&
            <Route
              exact
              path={routes.earnView.url + "/:id"}
              render={({ match }) => (
                <AffiliateData.Provider
                  id={Number.parseInt(match.params.id, 10)}
                  name={match.params.id}
                >
                  <AffiliateView />
                </AffiliateData.Provider>
              )} />
          }

          {routes.quizList &&
          routes.quizList.toggle !== undefined &&
          routes.quizList.toggle &&
          typeof routes.quizList.url === "string" &&
          routes.quizList.url.length > 0 &&
          <Route
            exact
            path={routes.quizList.url}
            render={() => {
              return (
                <QuizListData.Provider>
                  <QuizHistoryListData.Provider>
                    <QuizListView />
                  </QuizHistoryListData.Provider>
                </QuizListData.Provider>
              );
            }}
          />
          }

          {routes.quizList &&
          routes.quizList.toggle !== undefined &&
          routes.quizList.toggle &&
          typeof routes.quizList.url === "string" &&
          routes.quizList.url.length > 0 &&
          <Route
            exact
            path={`${routes.quizList.url}/:id`}
            render={({ match }) => {
              const id = Number.parseInt(match.params.id, 10);

              return (
                <QuizListData.Provider>
                  <QuizData.Provider id={id}>
                    <QuizHistoryListData.Provider>
                      <QuizView currentQuizId={id} />
                    </QuizHistoryListData.Provider>
                  </QuizData.Provider>
                </QuizListData.Provider>
              );
            }}
          />
          }

          {routes.quizHistory &&
          routes.quizHistory.toggle !== undefined &&
          routes.quizHistory.toggle &&
          typeof routes.quizHistory.url === "string" &&
          routes.quizHistory.url.length > 0 &&
            <Route
              exact
              path={`${routes.quizHistory.url}/:quizId/:id`}
              render={({ match }) => {
                const id = Number.parseInt(match.params.id, 10);
                const quizId = Number.parseInt(match.params.quizId, 10);

                return (
                  <QuizData.Provider id={quizId}>
                    <QuizHistoryData.Provider id={id}>
                      <QuizHistoryRowsData.Provider id={id}>
                        <QuizHistoryView />
                      </QuizHistoryRowsData.Provider>
                    </QuizHistoryData.Provider>
                  </QuizData.Provider>
                );
              }}
            />
          }

          {routes.photoServiceView &&
          routes.photoServiceView.toggle !== undefined &&
          routes.photoServiceView.toggle &&
          typeof routes.photoServiceView.url === "string" &&
          routes.photoServiceView.url.length > 0 &&
            <Route
              exact
              path={routes.photoServiceView.url}
              render={() => {
                return (
                  <PhotoServiceData.Provider>
                    <PhotoServiceListData.Provider>
                      <UploadedImages />
                    </PhotoServiceListData.Provider>
                  </PhotoServiceData.Provider>
                );
              }}
            />
          }

          {routes.accountView &&
          routes.accountView.toggle !== undefined &&
          routes.accountView.toggle &&
          typeof routes.accountView.url === "string" &&
          routes.accountView.url.length > 0 &&
            <Route
              exact
              path={routes.accountView.url}
              render={() => {
                if (customer.data) {
                  return (
                    <MemberGroupIsOwnerData.Provider>
                      <AccountFormConfigData.Provider
                        partner={Number.parseInt(info.partnerId, 10)}
                      >
                        <DistrictListData.Provider partner={Number.parseInt(info.partnerId, 10)}>
                          <AccountView customer={customer.data} />
                        </DistrictListData.Provider>
                      </AccountFormConfigData.Provider>
                    </MemberGroupIsOwnerData.Provider>
                  );
                }
              }}
            />
          }

          {routes.accountTransactionsView &&
          routes.accountTransactionsView.toggle !== undefined &&
          routes.accountTransactionsView.toggle &&
          typeof routes.accountTransactionsView.url === "string" &&
          routes.accountTransactionsView.url.length > 0 &&
            <Route
              path={routes.accountTransactionsView.url}
              render={({ location: { state } }) => (
                <TransactionsData.Provider name="full_view" filter={{ transactionFlag: flag }} lang={info.locale.slice(0, 2)}>
                  <TransactionsView hint={state && state.hint} />
                </TransactionsData.Provider>
              )}
            />
          }

          {routes.accountView &&
          routes.accountView.toggle !== undefined &&
          routes.accountView.toggle &&
          typeof routes.accountView.url === "string" &&
          routes.accountView.url.length > 0 &&
          routes.accountAdditionalMemberNumbers &&
          routes.accountAdditionalMemberNumbers.toggle !== undefined &&
          routes.accountAdditionalMemberNumbers.toggle &&
          typeof routes.accountAdditionalMemberNumbers.url === "string" &&
          routes.accountAdditionalMemberNumbers.url.length > 0 &&
            <Route
              path={routes.accountAdditionalMemberNumbers.url}
              render={() => (
                <AdditionalMemberNumbersView />
              )}
            />
          }

          {routes.accountingRecordsView &&
          routes.accountingRecordsView.toggle !== undefined &&
          routes.accountingRecordsView.toggle &&
          typeof routes.accountingRecordsView.url === "string" &&
          routes.accountingRecordsView.url.length > 0 &&
            <Route
              exact
              path={routes.accountingRecordsView.url}
              render={() => (
                <AccountingRecordsView />
              )}
            />
          }
          {routes.leaderboardView &&
          routes.leaderboardView.toggle !== undefined &&
          routes.leaderboardView.toggle &&
          typeof routes.leaderboardView.url === "string" &&
          routes.leaderboardView.url.length > 0 &&
            <Route
              exact
              path={routes.leaderboardView.url}
              render={() => (
                <LeaderboardData.Provider>
                  <LeaderboardView />
                </LeaderboardData.Provider>
              )}
            />
          }

          <Route
            exact
            path="/reports"
            render={() => <ReportsView />}
          />

          <Route
            exact
            path="/reports_history"
            render={() => <ReportsHistoryView />}
          />

          <Route
            exact
            path="/about"
            render={() => (<AboutView />)}
          />

          {routes.accountView &&
          routes.accountView.toggle !== undefined &&
          routes.accountView.toggle &&
          typeof routes.accountView.url === "string" &&
          routes.accountView.url.length > 0 &&
            <Route
              path={routes.accountView.url + "/points"}
              render={() => {
                if (customer.data) {
                  return (
                    <ConnectedAccountsData.Provider>
                      <PointsView customer={customer.data} />
                    </ConnectedAccountsData.Provider>
                  );
                }
              }}
            />
          }

          {routes.questionnaireView &&
          routes.questionnaireView.toggle !== undefined &&
          routes.questionnaireView.toggle &&
          typeof routes.questionnaireView.url === "string" &&
          routes.questionnaireView.url.length > 0 &&
            <Route
              exact
              path={[
                routes.questionnaireView.url + "/f/:filter",
                routes.questionnaireView.url,
              ]}
              render={({ match: { params } }) => {
                if (!customer.data) {
                  return null;
                }

                return (
                  <QuestionnaireListData.Provider>
                    <QuestionnaireListView filter={params.filter} />
                  </QuestionnaireListData.Provider>
                );
              }}
            />
          }

          {routes.questionnaireView &&
          routes.questionnaireView.toggle !== undefined &&
          routes.questionnaireView.toggle &&
          typeof routes.questionnaireView.url === "string" &&
          routes.questionnaireView.url.length > 0 &&
            <Route
              exact
              path={routes.questionnaireView && routes.questionnaireView.url + "/:slug"}
              render={({ match }) => {
                if (!customer.data || Number.isNaN(match.params.slug)) {
                  return null;
                }

                const id = Number.parseInt(match.params.slug, 10);

                return (
                  <QuestionnaireData.Provider id={id}>
                    <QuestionnaireView />
                  </QuestionnaireData.Provider>
                );
              }}
            />
          }

          {(routes.accountView &&
            routes.accountView.toggle !== undefined &&
            routes.accountView.toggle &&
            typeof routes.accountView.url === "string" &&
            routes.accountView.url.length > 0) && (
            routes.memberGroupView &&
            routes.memberGroupView.toggle !== undefined &&
            routes.memberGroupView.toggle &&
            typeof routes.memberGroupView.url === "string" &&
            routes.memberGroupView.url.length > 0) &&
            <Route
              path={`${routes.accountView.url}${routes.memberGroupView.url}/:page?`}
              render={({ match }) => {
                const { params, path } = match;
                const basePath = path.split("/").slice(0, -1).join("/");
                if (customer.data) {
                  return (
                    <MemberGroupIsOwnerData.Provider>
                      <MemberGroupView page={params.page} basePath={basePath} />
                    </MemberGroupIsOwnerData.Provider>
                  );
                }
              }}
            />
          }

          {routes.faqView &&
          routes.faqView.toggle !== undefined &&
          routes.faqView.toggle &&
          typeof routes.faqView.url === "string" &&
          routes.faqView.url.length > 0 &&
            <Route
              exact
              path={routes.faqView.url}
              render={() => {
                return (
                  <FaqView />
                );
              }} />
          }

          {routes.checkoutSuccessView &&
          routes.checkoutSuccessView.toggle !== undefined &&
          routes.checkoutSuccessView.toggle &&
          typeof routes.checkoutSuccessView.url === "string" &&
          routes.checkoutSuccessView.url.length > 0 &&
            <Route
              path={routes.checkoutSuccessView.url}
              render={({ history }) => (
                <OrderData.Provider>
                  <SuccessView history={history} />
                </OrderData.Provider>
              )}
            />
          }

          {routes.checkoutView &&
          routes.checkoutView.toggle !== undefined &&
          routes.checkoutView.toggle &&
          typeof routes.checkoutView.url === "string" &&
          routes.checkoutView.url.length > 0 &&
            <Route
              exact
              path={`${routes.checkoutView.url}/:step`}
              render={props => (
                <CmsData.Provider url={shopTermsUrl}>
                  <CheckoutView {...props} />
                </CmsData.Provider>
              )}
            />
          }

          {routes.brandsView &&
          routes.brandsView.toggle !== undefined &&
          routes.brandsView.toggle &&
          typeof routes.brandsView.url === "string" &&
          routes.brandsView.url.length > 0 &&
            <Route
              path={`${routes.brandsView.url}/:brand`}
              render={({ match, location }) => {
                if (!customer.data) {
                  return null;
                }

                return (
                  <BrandData.Provider
                    incVat={false}
                    name={match.params.brand}
                    brand={decodeURIComponent(match.params.brand)}
                    location={locationWithDefaults(location, pointsToPrice, defaultSort)}
                  >
                    <BrandView hint={location.state && location.state.hint} />
                  </BrandData.Provider>
                );
              }}
            />
          }

          {routes.brandsView &&
          routes.brandsView.toggle !== undefined &&
          routes.brandsView.toggle &&
          typeof routes.brandsView.url === "string" &&
          routes.brandsView.url.length > 0 &&
            <Route
              path={routes.brandsView.url}
              render={() => (
                <BrandListView />
              )}
            />
          }

          {routes.categoriesView &&
          routes.categoriesView.toggle !== undefined &&
          routes.categoriesView.toggle &&
          typeof routes.categoriesView.url === "string" &&
          routes.categoriesView.url.length > 0 &&
            <Route
              path={routes.categoriesView.url}
              render={() => (
                <CategoryListView />
              )}
            />
          }
          {routes.searchView &&
          routes.searchView.toggle !== undefined &&
          routes.searchView.toggle &&
          typeof routes.searchView.url === "string" &&
          routes.searchView.url.length > 0 &&
            <Route
              path={`${routes.searchView.url}/:query`} render={({ match, location, history, search }) => {
                if (!customer.data) {
                  return null;
                }

                const { page } = parseParams(search);

                return (
                  <SearchData.Provider
                    incVat={false}
                    location={locationWithDefaults(location, pointsToPrice, { code: "relevance", order: "DESC" })}
                    query={decodeURIComponent(match.params.query)}
                    name={`search_${match.params.query}`}
                    page={page}
                  >
                    <SearchView location={location} history={history} />
                  </SearchData.Provider>
                );
              }}
            />
          }

          {/** Add imported theme specific routes */}
          {additionalRoutes({ routes })}

          <Route
            path="/(.+)"
            render={({ location: { pathname, state, search }, history }) => {
              const { page } = parseParams(search);

              if (!customer.data) {
                return null;
              }

              return (
                <RouteData.Provider
                  incVat={false}
                  url={pathname}
                  location={locationWithDefaults(location, pointsToPrice, defaultSort)}
                  name={`route_${pathname}`}
                  hint={state && state.hint}
                  page={page}
                >
                  <RouteView location={location} history={history} />
                </RouteData.Provider>
              );
            }}
          />
        </Switch>
      </App>
    </ViewModeData.Provider>
  );
};

const AppRoutes = () => <Route component={Routes} />;

export default AppRoutes;
